/**
 * SSR渲染控制，因为镖局大多数页面属于功能，并且登录好才能使用，SSR意义不大，
 * 所以放在客户端渲染
 */
export default function(req, res, next) {
  res.spa = true;

  const pathName = req.url.replace(/\?.*$/, "");

  // 以下页面才在服务端渲染
  const srrPage = ["/"];

  if (srrPage.includes(pathName)) {
    res.spa = false;
  }
  next();
}
