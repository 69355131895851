//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ["error"],
    layout: "menu",
    mounted() {
        console.log(this.error);
    }
};
