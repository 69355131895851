// 引入ali-oss
import OSS from 'ali-oss';
import moment from "moment";





/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。{测试环境 bucket名称 ：zcd-dev ,线上环境 bucket名称 ：zcd-pro}
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。 // oss服务区域名称 'oss-cn-hangzhou'
 */

let client = (data) => {//data后端提供数据  process.env.VUE_APP_OSS_BUCKET
    let OSS_BUCKET = data.OssBucket;
    return new OSS({
        accessKeyId: data.AccessKeyId,
        accessKeySecret: data.AccessKeySecret,
        stsToken: data.SecurityToken,
        bucket: OSS_BUCKET,
        region: 'oss-cn-hangzhou'
    })
}





/**
 * 生成uuid
 */
let guid = ()=>{
    let S4 = ()=>{
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    return `${S4()}-${moment().format('YYYYMMDDHHmmss')}`
}
/**
 * 修改文件名字
 */
let getFileNameUid = (file, path) => {
    let arr = file.name.split(".");
    if(arr.length > 1){
        return moment().format(`${path}${guid()}`) +'.'+arr[arr.length-1];
    }else{
        return uuid;
    }
}








export default {
    client,
    getFileNameUid
}
