import Vue from 'vue'
// 引用组件
import SvgIcon from '@/components/SvgIcon.vue'

// 注册全局组件，组建名为svg-icon
Vue.component('svg-icon', SvgIcon)

// 引用svg图标
const requireAll = requireContext => requireContext.keys().map(requireContext)
// 如果第二个参数为 true ，程序将会遍历 assets/svg 目录下的子目录，并引用其中的 .svg 文件
// 如果不需要遍历子目录，可设为 false 
const req = require.context('@/assets/svg', true, /\.svg$/)
requireAll(req)