import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=01930c27&scoped=true&"
import script from "./menu.vue?vue&type=script&lang=js&"
export * from "./menu.vue?vue&type=script&lang=js&"
import style0 from "./menu.vue?vue&type=style&index=0&id=01930c27&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01930c27",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Update: require('/home/chofn/components/update.vue').default,SvgIcon: require('/home/chofn/components/SvgIcon.vue').default,Feedback: require('/home/chofn/components/feedback.vue').default,TopTip: require('/home/chofn/components/TopTip.vue').default})
