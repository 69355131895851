import { render, staticRenderFns } from "./signUp.vue?vue&type=template&id=2035f9d5&scoped=true&"
import script from "./signUp.vue?vue&type=script&lang=js&"
export * from "./signUp.vue?vue&type=script&lang=js&"
import style0 from "./signUp.vue?vue&type=style&index=0&id=2035f9d5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2035f9d5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Update: require('/home/chofn/components/update.vue').default,SvgIcon: require('/home/chofn/components/SvgIcon.vue').default})
