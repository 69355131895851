//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data(){
    return{
      interCheck: null,
      count: 10,
      VERSION_TIME: new Date().getTime(),
      updateDialog: false
    }
  },
  mounted() {
    this.$axios.get( '/version.json?t=' + Date.now(), {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '-1',
      },
    }).then(publicVersion => {
      this.VERSION_TIME = publicVersion.data.VERSION_TIME
    });
    this.startCheck();
  },
  methods: {
    startCheck(){
      if (!this.interCheck) {
        this.interCheck = setInterval(() => {
          this.checkVersion();
        }, 10000);
      }
    },
    checkVersion(){
      this.$axios.get( '/version.json?t=' + Date.now(), {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '-1',
        },
      }).then(publicVersion => {
        const NEW_VERSION_TIME = publicVersion.data.VERSION_TIME;
        if (NEW_VERSION_TIME && new Date(NEW_VERSION_TIME) > new Date(this.VERSION_TIME)) {
          this.endCheck();
          this.VERSION_TIME = NEW_VERSION_TIME;
          this.startUpdate();
        }
      });
    },
    endCheck(){
      clearInterval(this.interCheck);
      this.interCheck = null;
    },
    startUpdate(){
      // 开始自动更新倒计时
      this.count = 10;
      this.updateDialog = true;
      // 倒计时
      const timer = setInterval(() => {
        this.count--;
        if(!this.updateDialog) {
          clearInterval(timer);
        }
        if ( this.count === 1) {
          clearInterval(timer);
          // 强制刷新页面
          window.location.reload(true);
        }
      }, 1000);
    },
    refresh(){
      this.updateDialog = false;
      // 强制刷新页面
      window.location.reload(true);
    }
  }
}
