export const state = () => {
  return {
    user: null,
    noticeData: "", // 提醒
    isHideMenu: false,
    countryArr: [],
    areaArr: []
  };
};
export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  NOTICEDATA: (state, noticeData) => {
    state.noticeData = noticeData;
  },
  setMenu(state, isHideMenu) {
    state.isHideMenu = isHideMenu;
  },
  setCountryArr ( state, countryArr ) {
    state.countryArr = countryArr;
  },
  setAreaArr ( state, areaArr ) {
    state.areaArr = areaArr;
  }
};

export const actions = {
  async nuxtServerInit({}, { req, res, $axios, redirect }) {
    const url = req.url;
    if (url.includes("/login?code=")) {
      //说明jump必须放在URL最后，如果jump值必须以/开头
      //console.log(url, "导航站登录");
      let _getJump = url.lastIndexOf("&jump=");
      let _jump = "";
      let _url = url;
      if (_getJump > -1) {
        _jump = url.substring(_getJump + 6);
        //console.log("登录JUMP值=", _jump);
        _url = _url.substring(0, _getJump);
        //console.log("剔除jump参数后地址", _url);
      }
      var query = new URLSearchParams(_url.replace(/^.+\?/, ""));
      //console.log("登录参数", query);
      const loginRes = await $axios.get("/api/auth/syncLogin/", {
        params: query
      });
      //console.log("单点登录接口响应", loginRes.data);
      Object.keys(loginRes.headers).forEach(key => {
        if (key === "set-cookie") {
          res.setHeader("set-cookie", loginRes.headers[key]);
        }
      });
      // 获取uc_token，交到 /saveUcToke中存储
      const uc_token = loginRes.data.data.uc_token;
      //console.log("获取到uc_token", uc_token);
      if (url.includes("popularize")) {
        redirect(`/popularize?uc_token=${uc_token ? uc_token : ""}`);
      } else {
        redirect(`/saveUcToken?uc_token=${uc_token ? uc_token : ""}&jump=${_jump ? _jump : ""}`);
      }
    }
  }
};
