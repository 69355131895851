//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isTips: false,
      msgInfo: {
        content:
          "尊敬的用户,飙局于即日起开始不再保障IE9以下浏览器的可用性。建议您尽快升级浏览器，或者使用Chrome、Firefox。"
      }
    };
  },
  mounted() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isIE =
      userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isIE11 =
      userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      reIE.test(userAgent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (fIEVersion == 10) {
        console.log("IE v10");
      } else {
        //<=9
        console.log("<=ie9");

        var sh = this.$cookies.get("HideMsg");
        console.log(!(sh && sh == "hide"));
        if (!(sh && sh == "hide")) {
         this.isTips = true;
        }
      }
    } else if (isEdge) {
      console.log("edge"); //edge
    } else if (isIE11) {
      console.log("IE v11");
    } else {
      console.log("不是IE");
    }
    if (!this.isTips) {
      this.getMsg();
    }
  },
  methods: {
    tipsColse() {
      if (this.msgInfo.id) {
        this.$axios
          .post("/api/Inmail/setRead", { ids: this.msgInfo.id })
          .then(res => {
            this.isTips = false;
            this.getMsg();
          });
      } else {
        this.isTips = false;
        this.$cookies.set("HideMsg", "hide");
      }
    },
    getMsg() {
      // if (this.$route.path.indexOf('/queryReport') != -1 && this.$route.params.id) {  // 分享页不调用
      //   this.$axios.get("/api/Ajax/getTopNotice",{ params: { random: Math.random() }}).then(res => {
      //     if (!this.isEmpty(res.data.data)) {
      //       this.msgInfo = res.data.data;
      //       this.isTips = true;
      //     }
      //   });
      // }      
    },
    isEmpty(obj) {
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      if (
        typeof obj == "undefined" ||
        obj == null ||
        obj == "" ||
        re.test(obj)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
