import Vue from "vue";
import axios from "axios";
/**
 * 全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
 * 在网络慢的时候，加载图片多的时候，可以达到占位图的效果
 * 使用方法：<img src="默认图片.png" v-real-img="真实图片.png">
 */
Vue.directive('real-img', async function (el, binding) {//指令名称为：real-img
  let imgURL = binding.value;//获取图片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL);
    if (exist) {
      el.setAttribute('src', imgURL);
    }
    else {
      let _errorUrlTid = el.getAttribute('errorUrlTid');
      let _errorUrlSource = el.getAttribute('errorUrlSource');
      /*console.log(222,_errorUrlTid)
      console.log(222,_errorUrlSource)*/
      if(_errorUrlTid === null) return
      // 调用接口
      axios.post("/api/Ajax/handleImage/", {
            tids: _errorUrlTid,
            source: _errorUrlSource
          },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("uc_token")}`
          }
        }
      ).then(res => {
        console.log('调用成功')
      });
    }
  }
})

/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function (url) {
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = function () {
      if (this.complete == true) {
        resolve(true);
        img = null;
      }
    }
    img.onerror = function () {
      resolve(false);
      img = null;
    }
    img.src = url;
  })
}
